// estilos para resetear
@import "normalize";

// estilos propios
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    scroll-behavior: smooth; }

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    background-color: #E4E4E4;
 }    // height: auto
a {
    text-decoration: none;
    color: var(--color-primary); }

ul {
    list-style: inside; }

button, input, select, textarea {
    outline: none; }

button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px; }

// Colors generals

 :root {
    --color-primary: #782e40;
    --color-secondary: #fff6e1;
    --color-chat-personal-text: #bb005c; }

// Finish colors general

button[disabled], input[disabled] {
    cursor: no-drop !important;
    opacity: 0.5; }

.skeleton {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px;
    &.text {
        height: 18px; } }
@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0; }
    100% {
        background-position: 468px 0; } }

.rotate {
    animation: rotate 0.5s linear infinite; }

@keyframes rotate {
    to {
        transform: rotate(-360deg); } }

.charging:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(5,end) 900ms infinite;
    content: "\2026";
    width: 0px; }

@keyframes ellipsis {
    to {
        width: 1.25em; } }

.scroll {
    overflow-y: auto;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        -webkit-appearance: none; }
    &::-webkit-scrollbar:vertical {
        width: 20px; }
    &::-webkit-scrollbar:horizontal {
        height: 10px; }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        -webkit-box-shadow: none; }
    &::-webkit-scrollbar-thumb {
        background-color: #50505038;
        border-radius: 0;
        border-left: 10px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        &:hover {
            background-color: #50505094; }
        &:active {
            background-color: var(--color-primary); } } }

@media screen and (max-width: 425px) {
    .scroll {
        &::-webkit-scrollbar:vertical {
            width: 18px; } } }
