.dashboard_spinner {
    &--box {
        height: 100%;
        background: rgba(0, 0, 0, 0.0);
        // background: #fff
        width: 100%;
        // left: 0
        // top: 0
        display: flex;
        align-items: center;
        justify-content: center;
        // position: absolute
        &._--spinner-relative {
            position: relative;
            left: auto;
            height: auto;
            width: auto;
            top: auto;
            background: none; } }
    &--wrap {
        display: flex;
        flex-direction: row; }
    &--icon {
        width: 56px;
        height: 56px;
        // background-color: #1fc6be
        background: #535fe1;
        -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
        animation: sk-rotateplane 1.2s infinite ease-in-out; } }

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px); }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg); }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg); } }

@keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

.sk-chase {
    width: 80px;
    height: 80px;
    position: relative;

    animation: sk-chase 2.5s infinite linear both;

    &-dot {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        animation: sk-chase-dot 2.0s infinite ease-in-out both; }

    &-dot:before {
        content: '';
        display: block;
        width: 25%;
        height: 25%;
        background-color: var(--color-primary);
        border-radius: 100%;
        animation: sk-chase-dot-before 2.0s infinite ease-in-out both; }

    &-dot:nth-child(1) {
        animation-delay: -1.1s; }

    &-dot:nth-child(2) {
        animation-delay: -1.0s; }

    &-dot:nth-child(3) {
        animation-delay: -0.9s; }

    &-dot:nth-child(4) {
        animation-delay: -0.8s; }

    &-dot:nth-child(5) {
        animation-delay: -0.7s; }

    &-dot:nth-child(6) {
        animation-delay: -0.6s; }

    &-dot:nth-child(1):before {
        animation-delay: -1.1s; }

    &-dot:nth-child(2):before {
        animation-delay: -1.0s; }

    &-dot:nth-child(3):before {
        animation-delay: -0.9s; }

    &-dot:nth-child(4):before {
        animation-delay: -0.8s; }

    &-dot:nth-child(5):before {
        animation-delay: -0.7s; }

    &-dot:nth-child(6):before {
        animation-delay: -0.6s; } }

@keyframes sk-chase {
    100% {
        transform: rotate(360deg); } }

@keyframes sk-chase-dot {
    80%, 100% {
        transform: rotate(360deg); } }

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4); }
    100%, 0% {
        transform: scale(1.0); } }

.spinner1 {
    width: 56px;
    height: 56px;

    position: relative; }

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #1fc6be;
    background: #535fe1;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out; }

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s; }

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0.0); }
  50% {
    -webkit-transform: scale(1.0); } }

@keyframes sk-bounce {
    0%, 100% {
        -webkit-transform: scale(0.0);
        transform: scale(0.0); }
    50% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0); } }

.spinner2 {

    width: 50px;
    height: 56px;
    text-align: center;
    font-size: 10px;

    & > div {
        background-color: #1fc6be;
        background: #535fe1;
        height: 100%;
        width: 6px;
        margin: 0 1px;
        display: inline-block;

        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out; }

    & .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s; }

    & .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s; }

    & .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s; }

    & .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s; } }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1.0); } }

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4); }
    20% {
        -webkit-transform: scaleY(1.0);
        transform: scaleY(1.0); } }

.spinner3 {

    width: 56px;
    height: 56px;
    position: relative; }

.cube1, .cube2 {
    background-color: #1fc6be;
    background: #535fe1;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 1.8s infinite ease-in-out; }

.cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s; }

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5); }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg); }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5); }
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes sk-cubemove {
    25% {

        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
        transform: translateX(42px) rotate(-90deg) scale(0.5); }
    50% {

        -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
        transform: translateX(42px) translateY(42px) rotate(-179deg); }
    50.1% {

        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
        transform: translateX(42px) translateY(42px) rotate(-180deg); }
    75% {

        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
        transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5); }
    100% {

        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg); } }

.spinner4 {
    width: 56px;
    height: 56px;

    background-color: #1fc6be;
    background: #535fe1;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out; }

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0; } }

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0); }
    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0; } }

.spinner5 {

    width: 56px;
    height: 56px;
    position: relative;
    text-align: center;

    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear; }

.dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #1fc6be;
    background: #535fe1;
    border-radius: 100%;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out; }

.dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s; }

@-webkit-keyframes sk-rotate {
    100% {}
    -webkit-transform: rotate(360deg); }
@keyframes sk-rotate {
    100% {

        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0.0); }
  50% {
    -webkit-transform: scale(1.0); } }

@keyframes sk-bounce {
    0%, 100% {

        -webkit-transform: scale(0.0);
        transform: scale(0.0); }
    50% {

        -webkit-transform: scale(1.0);
        transform: scale(1.0); } }

.spinner6 {

    width: 70px;
    text-align: center;

    & > div {
        width: 18px;
        height: 18px;
        background-color: #1fc6be;
        background: #535fe1;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

    & .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s; }

    & .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s; } }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1.0); } }

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0); }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0); } }

.sk-circle {

    width: 56px;
    height: 56px;
    position: relative;

    & .sk-child {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0; }

    & .sk-child:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #1fc6be;
        background: var(--color-primary);
        border-radius: 100%;
        -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
        animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }

    & .sk-circle2 {
        -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        transform: rotate(30deg); }

    & .sk-circle3 {
        -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        transform: rotate(60deg); }

    & .sk-circle4 {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg); }

    & .sk-circle5 {
        -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
        transform: rotate(120deg); }

    & .sk-circle6 {
        -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
        transform: rotate(150deg); }

    & .sk-circle7 {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg); }

    & .sk-circle8 {
        -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
        transform: rotate(210deg); }

    & .sk-circle9 {
        -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
        transform: rotate(240deg); }

    & .sk-circle10 {
        -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg); }

    & .sk-circle11 {
        -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
        transform: rotate(300deg); }

    & .sk-circle12 {
        -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
        transform: rotate(330deg); }

    & .sk-circle2:before {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s; }

    & .sk-circle3:before {
        -webkit-animation-delay: -1s;
        animation-delay: -1s; }

    & .sk-circle4:before {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s; }

    & .sk-circle5:before {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s; }

    & .sk-circle6:before {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s; }

    & .sk-circle7:before {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s; }

    & .sk-circle8:before {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s; }

    & .sk-circle9:before {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s; }

    & .sk-circle10:before {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s; }

    & .sk-circle11:before {
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s; }

    & .sk-circle12:before {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s; } }

@-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {}
    -webkit-transform: scale(0);
    transform: scale(0);
    40% {}
    -webkit-transform: scale(1);
    transform: scale(1); }

@keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0); }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1); } }

.sk-cube-grid {
    width: 56px;
    height: 56px;

    & .sk-cube {
        width: 33%;
        height: 33%;
        background-color: #1fc6be;
        background: #535fe1;
        float: left;
        -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
        animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; }

    & .sk-cube1 {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s; }

    & .sk-cube2 {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s; }

    & .sk-cube3 {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s; }

    & .sk-cube4 {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s; }

    & .sk-cube5 {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s; }

    & .sk-cube6 {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s; }

    & .sk-cube7 {
        -webkit-animation-delay: 0s;
        animation-delay: 0s; }

    & .sk-cube8 {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s; }

    & .sk-cube9 {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s; } }

@-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {}
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
    35% {}
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1); }

@keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1); }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1); } }

.sk-fading-circle {

    width: 56px;
    height: 56px;
    position: relative;

    & .sk-circle {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0; }

    & .sk-circle:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #1fc6be;
        background: #535fe1;
        border-radius: 100%;
        -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
        animation: sk-circleFadeDelay 1.2s infinite ease-in-out both; }

    & .sk-circle2 {
        -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        transform: rotate(30deg); }

    & .sk-circle3 {
        -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        transform: rotate(60deg); }

    & .sk-circle4 {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg); }

    & .sk-circle5 {
        -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
        transform: rotate(120deg); }

    & .sk-circle6 {
        -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
        transform: rotate(150deg); }

    & .sk-circle7 {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg); }

    & .sk-circle8 {
        -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
        transform: rotate(210deg); }

    & .sk-circle9 {
        -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
        transform: rotate(240deg); }

    & .sk-circle10 {
        -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg); }

    & .sk-circle11 {
        -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
        transform: rotate(300deg); }

    & .sk-circle12 {
        -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
        transform: rotate(330deg); }

    & .sk-circle2:before {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s; }

    & .sk-circle3:before {
        -webkit-animation-delay: -1s;
        animation-delay: -1s; }

    & .sk-circle4:before {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s; }

    & .sk-circle5:before {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s; }

    & .sk-circle6:before {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s; }

    & .sk-circle7:before {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s; }

    & .sk-circle8:before {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s; }

    & .sk-circle9:before {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s; }

    & .sk-circle10:before {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s; }

    & .sk-circle11:before {
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s; }

    & .sk-circle12:before {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s; } }

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

@keyframes sk-circleFadeDelay {
    0%, 39%, 100% {
        opacity: 0; }
    40% {
        opacity: 1; } }

.sk-folding-cube {
    width: 56px;
    height: 56px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);

    & .sk-cube {
        float: left;
        width: 50%;
        height: 50%;
        position: relative;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1); }

    & .sk-cube:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #1fc6be;
        background: #535fe1;
        -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
        animation: sk-foldCubeAngle 2.4s infinite linear both;
        -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
        transform-origin: 100% 100%; }

    & .sk-cube2 {
        -webkit-transform: scale(1.1) rotateZ(90deg);
        transform: scale(1.1) rotateZ(90deg); }

    & .sk-cube3 {
        -webkit-transform: scale(1.1) rotateZ(180deg);
        transform: scale(1.1) rotateZ(180deg); }

    & .sk-cube4 {
        -webkit-transform: scale(1.1) rotateZ(270deg);
        transform: scale(1.1) rotateZ(270deg); }

    & .sk-cube2:before {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s; }

    & .sk-cube3:before {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s; }

    & .sk-cube4:before {
        -webkit-animation-delay: 0.9s;
        animation-delay: 0.9s; } }

@-webkit-keyframes sk-foldCubeAngle {
    0%, 10% {}
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
    25%, 75% {}
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
    90%, 100% {}
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; }

@keyframes sk-foldCubeAngle {
    0%, 10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0; }
    25%, 75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1; }
    90%, 100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0; } }

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em; }

.loader {

    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid #888;
    border-right: 1.1em solid #888;
    border-bottom: 1.1em solid #888;
    border-left: 1.1em solid #1fc6be;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }

.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    &:after {
        content: " ";
        display: block;
        border-radius: 50%;
        width: 0;
        height: 0;
        margin: 8px;
        box-sizing: border-box;
        border: 32px solid #1fc6be;
        border-color: #1fc6be transparent #1fc6be transparent;
        animation: lds-hourglass 1.2s infinite; } }

@keyframes lds-hourglass {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

    100% {
        transform: rotate(1800deg); } }

.lds-dual-ring {
    display: block;
    width: 80px;
    height: 80px;

    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #1fc6be;
        border-color: #1fc6be transparent #1fc6be transparent;
        animation: lds-dual-ring 1.2s linear infinite; } }

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }
